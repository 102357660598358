import { en } from "./en";
import { fr, TranslationObject } from "./fr";

const translations: { [key in LocaleString]: TranslationObject } = {
  fr,
  "en-gb": en,
};
export default translations;

export const AVAILABLE_LOCALES = ["fr", "en-gb"] as const;
export type LocaleString = typeof AVAILABLE_LOCALES[number];

export const LOCALE_LABELS: { [key in LocaleString]: string } = {
  fr: "FR",
  "en-gb": "EN",
};
